import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { FaCalendarAlt, FaMapMarkerAlt } from 'react-icons/fa'

import getEventDateString from '../../helpers/getEventDateString';

import * as styles from './EventCard.module.scss'

const EventCard = (props) => {
  const {
    _id,
    title,
    type,
    startDate,
    endDate,
    location
  } = props.data

  return (
    <StyledEventCard>
      <div className="featured-image">
        <div className="title-container">
          <h3>{title}</h3>
        </div>
        {location 
          ? <GatsbyImage image={getImage(location.featuredImage.asset)} alt={location.name} style={{gridArea: '1/1'}} />
          : <StaticImage src='../../images/event-tbc.jpg' alt="Event TBC" style={{ gridArea: '1/1' }} />
        }
      </div>
      <div className="event-card-content">
        <p>{type}</p>
        <ul>
          <li><FaCalendarAlt />{getEventDateString(startDate, endDate)}</li>
          <li><FaMapMarkerAlt />{`${location?.name || 'TBC'}`}</li>
        </ul>
        <Link to={`/events/${_id}/`} className={styles.btnEventCard}>See more</Link>
      </div>
    </StyledEventCard>
  );
};

const StyledEventCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: #222;
  max-width: 600px;
  min-height: 200px;
  border-radius: 20px;
  overflow: hidden;
  text-transform: uppercase;

  .title-container,
  .event-card-content {
    padding: 1rem;
  }

  .featured-image {
    display: grid;
    background-color: #333;

    .title-container {
      grid-area: 1/1;
      z-index: 1;
      background: rgb(0,0,0);
      background: linear-gradient(10deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 64%);
      position: relative;

      h3 {
        position: absolute;
        bottom: 1rem;
        font-size: 1.85rem;
        line-height: 0.95;
      }

      &:after {
        content: '';
        background-color: var(--primary-color);
        width: 1.9rem;
        height: 1.9rem;
        position: absolute;
        top: 1rem;
        border-radius: 50%;
      }
    }
  }

  .event-card-content {
    display: flex;
    flex-direction: column;

    p {
      margin-bottom: 0.8rem; 
      font-size: 1.1rem;
      font-weight: 600;
    }

    ul {
      font-size: 1.7rem;
      font-weight: 600;
      line-height: 1.3;
      list-style: none;

      svg {
        font-size: 0.8em;
        margin-right: 0.5em;
      }
    }

    a.event-card-btn {
      margin-top: auto;
    }
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    grid-template-rows: 200px 220px;

    .featured-image {
      img {
        border-radius: 20px 20px 0 0;
      }
    }

    a.event-card-btn {
      width: 100%;
      padding: 0.5em;
      text-align: center;
    }
  }
`

export default EventCard;
