import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import * as styles from './Showcase.module.scss'

const Showcase = () => {
  return (
    <section id={styles.showcase}>
      <div className={`site-container ${styles.showcaseContainer}`}>
        <div className={`${styles.showcaseContent}`}>
          <StaticImage 
            className={styles.sarplLogoImage}
            src='../../images/sarpl-logo.png' 
            alt='SARPL' 
            loading='eager' 
            width={275} 
            objectFit='contain' 
            placeholder='tracedSVG'
          />
          <h2>South Africa's Largest Paintball League</h2>
          <Link to="/register/" className={styles.btnShowcase}>Register Now</Link>
        </div>

        <StaticImage
          className={styles.featuredPlayerImage}
          src='../../images/featured-player-(glossy).png'
          alt=''
          height={500}
          loading='eager'
          placeholder='none'
          objectFit='contain'
        />
      </div>
    </section>
  )
}

export default Showcase