import React from 'react';
import styled from 'styled-components';

const SeasonSponsors = ({sponsors}) => {

  const sponsorTiers = {}
  for (const sponsor of sponsors) {
    if (sponsorTiers[sponsor.tier]) {
      sponsorTiers[sponsor.tier].push(sponsor)
    } else {
      sponsorTiers[sponsor.tier] = [sponsor]
    }
  }

  const sponsorshiptTierElements = []
  for (const tier in sponsorTiers) {
    sponsorshiptTierElements.push(
      <div key={tier} className={tier}>
        {sponsorTiers[tier].map(({sponsor}) => (
          <a key={sponsor.name} title={sponsor.name} href={sponsor.website} target='_blank' rel='noreferrer'>
            <img src={sponsor.logo.asset.url} alt={sponsor.name} />
          </a>
        ))}
      </div>
    )
  }

  return (
    <StyledSeasonSponsors>
      {sponsorshiptTierElements}
    </StyledSeasonSponsors>
  );
};

const StyledSeasonSponsors = styled.article`
  display: flex;
  flex-flow: row wrap;
  gap: 0.75rem;
  background: #1f1f1f;
  padding: 0.5rem;
  border-radius: 38px;

  & > div {
    flex: 1 1 200px;
    display: flex;
    justify-content: space-evenly;
    padding: 0.4rem;
    border-radius: 50px;

    img {
      width: 80px;
      height: 35px;
      object-fit: contain;
      margin: 0.2rem 0.5rem;
      transition: transform 0.4s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

  div.Gold { background-color: gold; }
  div.Silver { background-color: silver; }
  div.Paint { background-color: #00B4F0; }

  @media (max-width: 480px) {

    & > div {
      img {
        height: 30px;
      }
    }
  }
`

export default SeasonSponsors;
