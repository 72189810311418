import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import getEventDateString from '../../helpers/getEventDateString'

import * as styles from './EventListItem.module.scss'

const EventListItem = (props) => {
  const {
    _id,
    title,
    type,
    startDate,
    endDate,
    location
  } = props.data

  return (
    <StyledEventListItem className={`--${type.toLowerCase()}`}>
      <div className="row-container">
        <span>{type}</span>
        <span>{title}</span>
        <span className="event-date">{getEventDateString(startDate, endDate)}</span>
        <span className="event-location">{location?.name || 'TBC'}</span>
      </div>
      <Link to={`/events/${_id}/`} className={styles.btnEventCard}><span>See more&nbsp;</span>&rsaquo;</Link>
    </StyledEventListItem>
  )
}

const StyledEventListItem = styled.div`
  --row-padding: 0.5rem;

  display: grid;
  grid-template-columns: 1fr auto;
  /* grid-gap: 1rem; */
  /* background-color: #222; */
  padding-left: 3rem;
  /* padding-right: var(--row-padding); */
  margin-bottom: 0.5rem;
  border-radius: 50px;
  font-size: 1.2rem;
  line-height: 1;
  text-transform: uppercase;
  position: relative;
  
  &.--3-man { background-color: var(--primary-color); }
  &.--5-man { background-color: maroon; }
  &.--supercup { background-color: red; }
  
  .row-container {
    display: grid;
    grid-template-columns: 150px repeat(auto-fit, minmax(170px, 1fr));
    grid-gap: 1rem 0.5rem;
    padding: var(--row-padding);

    & > * {
      align-self: center;
    }
  }
  
  /* & > span {
    padding: 0.5rem;
    display: inline-block;
    vertical-align: center;
    align-self: center;

    &:nth-child(2) {
      font-weight: bold;
      letter-spacing: 1px;
      background-color: #111;
      /* text-align: center; 
      border-radius: 50px;
      padding-left: 1rem;
    }
  } */

  a {
    background-color: #111;
    border-color: #111;
    margin: var(--row-padding);

    &:hover {
      background-color: inherit;
    }
  }

  &:before {
    content: '';
    background-color: #111;
    height: calc(100% - (var(--row-padding) * 2));
    max-height: 2rem;
    aspect-ratio: 1 / 1;
    margin: var(--row-padding);
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
  }

  @media (max-width: 768px) {
    border-radius: 20px;

    &:before {
      height: 1.6rem;
    }

    a {
      /* content: '>'; */
      background-color: inherit;
      width: 100%;
      margin: 0;
      border: none;
      border-left: solid 2px rgba(255, 255, 255, 0.2);
      border-radius: 0 20px 20px 0;

      color: rgba(255, 255, 255, 0.75);
      font-size: 2rem;

      span {
        display: none;
      }
    }
  }

  @media (max-width: 480px) {
    padding-left: 2.2rem;
    font-size: 1rem;

    .row-container {
      /* grid-template-columns: 130px 140px; */
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      grid-gap: 0.5rem;
    }
  }
`

export default EventListItem