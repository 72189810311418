import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'

import Showcase from "../components/Showcase"
import SeasonSponsors from "../components/SeasonSponsors"
import EventCard from "../components/EventSummaries/EventCard"
import EventsList from "../components/EventSummaries/EventsList"
import RulebookBtn from "../components/RulebookBtn"
import Seo from "../components/SEO"

const IndexPage = ({ data }) => {
  const season = data.sanitySiteSettings.activeSeason
  const rulebooks = data.sanitySiteSettings.rulebooks
  const socials = data.sanityCompanyInfo.socials
  const events = data.allSanityEvent.nodes.filter(event => event.eventSeason.year === season.year)

  // currentDate is used to sort events.
  const currentDate = new Date()

  let liveEvent;
  const upcomingEvents = []
  const pastEvents = []

  for (let event of events) {
    if (new Date(event.startDate) > currentDate) {
      upcomingEvents.push(event)
    } else if (new Date(event.endDate) < currentDate) {
      pastEvents.push(event)
    } else {
      liveEvent = event
    }
  }

  const rulebookElements = rulebooks.map(({ title, asset}) => (
    <RulebookBtn key={title} title={title} assetURL={asset.url} />
  ))

  return (
    <StyledIndexPage>
      <Seo />
      <Showcase />

      {/* <div className="page-container"> */}
        <section id="season" className="site-container">
          <h2>Season {season.year}</h2>
          <SeasonSponsors sponsors={season.seasonSponsors} />

          {liveEvent ? (
            <article id="next-event">
              <h3>Live Event:</h3>
              <EventCard data={liveEvent} />
            </article>
          ) : upcomingEvents.length !== 0 && (
            <article id="next-event">
              <h3>Next Event:</h3>
              <EventCard data={upcomingEvents.shift()} />
            </article>
          )}

          <article id="all-upcoming-events">
            <h3>Upcoming Events:</h3>
            {upcomingEvents.length !== 0 
              ? <EventsList events={upcomingEvents} />
              : <p>There are no upcoming events</p>
            }
          </article>

          {pastEvents.length !== 0 && <article id="all-upcoming-events">
            <h3>Past Events:</h3>
            <EventsList events={pastEvents} />
          </article>}

          <article id="season-info">
            <h3>Season info:</h3>

            <div className="rulebooks">
              {rulebookElements}
            </div>
          </article>

          <article id="socials">
            {/* <h3>Our socials:</h3> */}
            <div className="icons">
              {socials.facebook && <a href={socials.facebook} target="_blank" rel='noreferrer'>
                <FaFacebook /><span className="visually-hidden">Facebook</span>
              </a>}
              {socials.instagram && <a href={socials.instagram} target="_blank" rel='noreferrer'>
                <FaInstagram /><span className="visually-hidden">Instagram</span>
              </a>}
              {socials.youtube && <a href={socials.youtube} target="_blank" rel='noreferrer'>
                <FaYoutube /><span className="visually-hidden">YouTube</span>
              </a>}
            </div>
          </article>
        </section>
      {/* </div> */}
    </StyledIndexPage>
  )
}

export const query = graphql`
  query IndexPageQuery { # ($currentISODate: Date)
    sanityCompanyInfo {
      socials {
        facebook
        instagram
        tiktok
        twitter
        youtube
      }
    }
    sanitySiteSettings {
      activeSeason {
        year
        seasonSponsors {
          sponsor {
            name
            logo { asset { url } }
            website
          }
          tier
        }
      }
      rulebooks {
        title
        type
        asset { url }
      }
    }
    # allSanityEvent(filter: {startDate: {gt: $currentISODate}}, sort: {fields: startDate}) {
    allSanityEvent(sort: {fields: startDate}) {
      nodes {
        _id
        title
        type
        startDate
        endDate
        location {
          name
          featuredImage {
            asset {
              gatsbyImageData
            }
          }
        }
        eventSeason {
          year
        }
      }
    }
  }
`

const StyledIndexPage = styled.main`
  overflow-x: hidden;

  /* .page-container {
    padding: 0 1rem;
  } */

  section#season {
    h2 {
      font-family: 'Kanit', sans-serif;
      font-weight: 700;
      font-size: 6rem;
      font-style: italic;
      text-align: center;
      line-height: 1.2;
      position: relative;

      &:after {
        content: '';
        background-color: var(--secondary-color);
        height: 0.04em;
        width: 2em;
        position: absolute;
        bottom: 0;
        left: calc(50%);
        transform: translateX(-50%);
      }
    }

    & > article {
      margin: 3rem 0;

      & > h3 {
        margin-bottom: 1rem;
        font-size: 1.7rem;
        font-weight: 200;
        letter-spacing: 2px;
        text-transform: uppercase;
      }
    }

    article#socials {
      div.icons {
        display: flex;
        justify-content: space-around;
        max-width: 600px;
        margin: 2rem auto 0 auto;

        svg {
          font-size: 2.2rem;

          &:hover {
            color: var(--primary-color);
          }
        }
      }
    }

    div.rulebooks {
      display: flex;
      flex-direction: row wrap;
    }
  }

  @media (max-width: 480px) {
    section#season {
      h2 {
        font-size: 3.75rem;
      }
    }
  }
`

export default IndexPage
